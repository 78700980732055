import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { chooseMaxPixels, convertToPixels, uniqueComponentKey } from '../utility'
import useDeltaPosition from './useDeltaPosition'
import { updateDimension } from '../../features/components/componentSlice'
import { round } from 'lodash'

const DELETE_KEY = 8 || 46

const snapDimension = (dimension, value, position) => {
  switch (dimension) {
    case "x":
      return Math.max(0, Math.min(100 - position.width, value))
    case "y":
      return Math.max(0, Math.min(100 - position.height, value))
    case "width":
      return Math.max(0, Math.min(100 - position.x, value))
    case "height":
      return Math.max(0, Math.min(100 - position.y, value))
  }
}

const useTransformFieldProperties = (id, page, position) => {
  const dispatch = useDispatch()
  const key = uniqueComponentKey(...id)
  const currentPageWidth = useSelector(({pages}) => pages.pageWidth)
  const {width: pageWidth, height: pageHeight } = useSelector(({pages}) => pages.all[page].page_sizes[currentPageWidth])

  const [deltaPosition, updateDeltaPosition] = useDeltaPosition(position, pageWidth, pageHeight)

  const handlePositionChange = useCallback((dimension) => {
    const value = snapDimension(dimension, deltaPosition[dimension], deltaPosition)
    dispatch(updateDimension({ key, value, dimension }))
  }, [ dispatch, deltaPosition ])

  const handleEnterKey = useCallback((e, dimension) => {
    if (e.key === 'Enter') {
      handlePositionChange(dimension)
      e.target.blur()
    }
  }, [handlePositionChange])

  const handleFocus = useCallback(e => e.target.select(), [])

  const handleKeyUp = useCallback(e => {
    if(e.keyCode === DELETE_KEY) e.stopPropagation()
  }, [])

  const getValue = useCallback(dimension => {
    return convertToPixels(deltaPosition[dimension], chooseMaxPixels(dimension, pageWidth, pageHeight))
  }, [deltaPosition, pageWidth, pageHeight])

  return useCallback((dimension) => ({
    value: round(getValue(dimension), 2) || 0,
    onChange: e => updateDeltaPosition(dimension, +e.target.value),
    onBlur: () => handlePositionChange(dimension),
    onKeyDown: e => handleEnterKey(e, dimension),
    onFocus: handleFocus,
    onKeyUp: handleKeyUp
  }), [deltaPosition, updateDeltaPosition, handlePositionChange, handleEnterKey])
}

export default useTransformFieldProperties
