import React, { useCallback, useEffect, useRef, useState } from 'react'
import useClipboard from '../../hooks/useClipboard'
import SectionDivider from '../SectionDivider'
import Input from '../../Input'
import { uniqueComponentKey } from '../../utility'
import { useDispatch } from 'react-redux'
import { updateFieldText } from '../../../features/components/componentSlice'
import useMultiControlledInput from '../../hooks/useMultiControlledInput'

const DELETE_KEY = 8 || 46

const IdField = ({id}) => {
  const { isCopied, copyToClipboard } = useClipboard()
  const [innerText, setInnerText] = useState('')
  const ref = useRef()

  useEffect(() => {
    setInnerText(ref.current.innerText)
  }, [ref])

  const handleCopyClick = () => copyToClipboard(innerText)

  return (
    <>
      <div className='input-label-group unique-id'>
        {isCopied && <div className='copy-tooltip'>Copied!</div>}
        <label>id</label>
        <div ref={ref} className='data-field-id input-rounded' onClick={handleCopyClick} style={{cursor: 'pointer'}}>
          {id[1]}
        </div>
      </div>
    </>
  )
}

const useBehaviorProperties = (id, component) => {
  const dispatch = useDispatch()
  const key = uniqueComponentKey(...id)
  const [values, setValues, handleChange] = useMultiControlledInput({
    ...component?.behaviorInputs
  })

  useEffect(() => {
    setValues(component?.behaviorInputs)
  }, [component])

  const handleInputChange = useCallback((e) => {
    const { name } = e.target

    if (values[name] === component?.behaviorInputs[name]) return

    dispatch(updateFieldText({ key, field: name, text: values[name] }))
  }, [dispatch, values, key, component])

  const handleInputChangeRef = useRef(handleInputChange)

  const handleEnterKey = useCallback((e) => {
    if (e.key === 'Enter') {
      handleInputChangeRef?.current(e)
      e.target.blur()
    }
  }, [handleInputChangeRef])

  const handleFocus = useCallback((e) => e.target.select(), [])

  const handleKeyUp = useCallback(e => {
    if(e.keyCode === DELETE_KEY) e.stopPropagation()
  }, [])

  return useCallback(({name,label}) => ({
    id: name,
    name,
    label: label,
    value: values[name],
    onChange: handleChange,
    onBlur: handleInputChange,
    onKeyDown: handleEnterKey,
    onFocus: handleFocus,
    onKeyUp: handleKeyUp
  }), [handleInputChange, handleEnterKey, handleFocus, values, handleChange])
}

const Behaviors = ({ id, component, showPlaceholder=true, showLabel=true }) => {
  const generateProperties = useBehaviorProperties(id, component)

  return (
    <SectionDivider sectionName='behaviors'>
      <IdField id={id}/>
      {showLabel &&<Input {...generateProperties({name:"label",label:"label"})} />}
      {showPlaceholder && <Input {...generateProperties({name:"placeholder",label:"placeholder text"})} />}
    </SectionDivider>
  )
}

export default Behaviors
