import React, { useCallback, useEffect, useState } from 'react'
import { sortedTools } from './Tools'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setActive } from '../../features/tools/toolSlice'
import { compact,cloneDeep } from 'lodash-es'
import { gatherSelectedComponents, useComponentCreator, useComponentDuplicate } from '../../features/components/componentSlice'
import { useEventManager } from '@deathbyjer/react-event-manager'
import { useDataTemplates } from 'components/form_builder/features/data/dataSlice'
import useSelectedDependentFields from '../hooks/useSelectedDependentFields'

const groupedTools = Object.values(sortedTools).reduce((groups, tool) => {
  const groupKey = tool.group

  if (!groups[groupKey]) {
    groups[groupKey] = []
  }
  groups[groupKey].push(tool)

  return groups
}, {})

const AlignDropdown = () => {

  const handleClick = (e) => {
    console.log('clicked', e.target)
  }


  return (
    <div onClick={handleClick} className='dropdown-container'>
      <div>align</div>
    </div>
  )
}

const ZoomDropdown = () => {
  return (
    <div className='dropdown-container'>
      <div>zoom</div>
    </div>
  )
}

const useToolActions = ({ tool, activeTool, setToggleDropdown }) => {
  const dispatch = useDispatch()
  const components = useSelector(gatherSelectedComponents, shallowEqual)
  const createDuplicateComponent = useComponentDuplicate()
  const events = useEventManager()

  const handleDuplicate = useCallback(() => {
    if (components) {
      const newComponents = []
      for (const component of components) {
        newComponents.push(createDuplicateComponent(component)) 
      }
      const keys = newComponents.map((component) => [component.type, component.id, component.subtype]) 
      events.applyEventListeners('duplicateComponent', keys)
    }
  }, [components]);

  const handleAlign = useCallback(() => {
    setToggleDropdown((prevState) => !prevState);
  }, [setToggleDropdown]);

  const handleZoom = useCallback(() => {
    setToggleDropdown((prevState) => !prevState);
  }, [setToggleDropdown]);

  return useCallback(() => {
    if (tool.disabled) return;

    if (activeTool !== tool.name && !tool.group.includes('actions')) {
      dispatch(setActive(tool.name));
    }

    switch (tool.name) {
      case 'duplicate':
        handleDuplicate();
        break;
      case 'align':
        handleAlign();
        break;
      case 'zoom':
        handleZoom();
        break;
      default:
    }
  }, [activeTool, dispatch, setActive, tool, handleDuplicate, handleAlign, handleZoom]);
};

const ToolButton = ({ tool, isActive, activeTool, disabled }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const handleClick = useToolActions({tool, activeTool, setToggleDropdown})

  const buttonClasses = [
    "tool",
    `tool-${tool.elementType}`,
    tool.disabled || disabled ? "disabled" : null,
    isActive ? "active" : null,
  ]

  return (
    <div onClick={handleClick} className={compact(buttonClasses).join(" ")}>
      <div className='tool-icon-wrapper'>
        <i className={`icon ${tool.icon}`}></i>
        <div className='name'>{tool.name}</div>
      </div>
      {tool.name === 'align' && toggleDropdown && <AlignDropdown name={tool.name} />}
      {tool.name === "zoom" && toggleDropdown && <ZoomDropdown name={tool.name} />}
    </div>
  )
}

const ToolsList = () => {
  const dispatch = useDispatch()
  const selectedDependentFields = useSelectedDependentFields()
  const activeTool = useSelector(({ tools }) => tools.active)

  useEffect(() => {
    dispatch(setActive('select'))
  }, [])

  return (
    Object.keys(groupedTools).map(groupKey => (
      <div key={groupKey} className={`tool-group group-${groupKey}`}>
        {groupedTools[groupKey].map((tool, index) => {
          const isActive = activeTool === tool.name
          const disableDuplicate = tool.name === 'duplicate' && selectedDependentFields.length > 0
          return (
            <ToolButton 
              key={index}
              tool={tool}
              isActive={isActive}
              activeTool={activeTool}
              disabled={disableDuplicate}
            />
          )
        })}
      </div>
    ))
  )
}

const Toolbar = () => {
  return (
    <div className="toolbar draw-tools">
      <ToolsList />
    </div>
  )
}

export default Toolbar
