import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEventListeners, useEventManager } from '@deathbyjer/react-event-manager'
import { eventForId } from 'components/utility/draw_area'
import { calNewRectDuplicateEl, splitComponentKey, uniqueComponentKey } from '../../common/utility'
import { Tools } from '../../common/toolbar/Tools'
import Behaviors from 'components/form_builder/common/sidebars/right/Behaviors'
import Transform from 'components/form_builder/common/sidebars/right/Transform'
import { removeComponents, updatePosition, useComponentCreator, useDependentFieldCreator } from './componentSlice'
import Templating from '../../common/sidebars/right/Templating'
import clsx from 'clsx'
import SectionDivider from 'components/form_builder/common/sidebars/SectionDivider'
import { DependentFieldModal } from 'components/form_builder/modal/DependentFieldModal'

export const Highlight = ({ id, setId }) => {
  const key = uniqueComponentKey("highlight", id)
  const highlight = useSelector(state => state.components.all[key])
  const isNewest = useSelector(state => key == state.components.newest)

  const fullId = ["highlight", id]
  setId ? setId(fullId) : null

  useEventListeners({
    [eventForId("rect-updated", fullId)]: ({ rect }) => {
      console.log(rect)
    },
  })

  return <div style={{ background: highlight.color, height: "100%", opacity: .5 }}></div>
}

// Field Area
const FieldComponent = ({ id, type, subtype, setId, selected }) => {
  const dispatch = useDispatch()
  const fullId = useMemo(() => [type, id, subtype].filter(Boolean), [type, id, subtype])
  const key = uniqueComponentKey(...fullId)
  const component = useSelector((state) => state.components.all[key])

  setId ? setId(fullId) : null

  useEventListeners({
    [eventForId('rect-updated', fullId)]: ({ rect }) => {
      dispatch(updatePosition({ key, rect }))
    },
  })

  return (
    <>
      <div className="input-area-field dome-d-flex dome-justify-center dome-flex-column dome-full-height">
        <p>{component?.behaviorInputs?.placeholder}</p>
      </div>
      {selected && subtype === 'signature' && <DependentFieldModal signatureComponent={component}  />}
    </>
  )
}

export const DataField = (props) => <FieldComponent {...props} type="data" />
export const SignatureFieldSignature = (props) => <FieldComponent {...props} type="signature" subtype="signature" />
export const SignatureFieldName = (props) => <FieldComponent {...props} type="signature" subtype="name" />
export const SignatureFieldTime = (props) => <FieldComponent {...props} type="signature" subtype="time" />

// Toolbars
const DependentFieldLabel = {
  time: 'date signed',
  name: 'full name',
}

export const SingleSignatureWithSingleDataToolbar = ({ components }) => {
  const dispatch = useDispatch()
  const createDependentFieldComponent = useDependentFieldCreator()

  const { key: dataKey, page, rect } = components.filter((c) => c.type == 'data')[0]
  const { id: signatureId } = components.filter((c) => c.type == 'signature')[0]

  const swapComponent = useCallback(
    (subtype) => {
      createDependentFieldComponent({id: signatureId, type: 'signature', page, rect, subtype})
      dispatch(removeComponents([dataKey]))
    },
    [dispatch, dataKey, page, rect, signatureId, createDependentFieldComponent]
  )

  const handleConvertToSignatureName = useCallback(() => swapComponent('name'), [swapComponent])
  const handleConvertToSignatureTime = useCallback(() => swapComponent('time'), [swapComponent])

  return (
    <div className="side-toolbar signature-w-data-toolbar scrollable">
      <button onClick={handleConvertToSignatureName}>convert to signature name</button>
      <button onClick={handleConvertToSignatureTime}>convert to signature time</button>
    </div>
  )
}

export const SignatureSingleToolbar = ({ id, component }) => {
  const signatureKey = uniqueComponentKey(component.type, component.id, 'signature')
  const signatureComponent = useSelector((state) => state.components.all[signatureKey])

  return (
    <div className="side-toolbar signature-toolbar scrollable">
      <Behaviors id={id} component={component} showPlaceholder={true} showLabel={false} />
      <Transform
        id={splitComponentKey(signatureKey)}
        page={signatureComponent?.page}
        position={signatureComponent?.rect}
      />
      {Object.keys(DependentFieldLabel).map((subtype) => (
        <DependentSingleToolbar
          key={subtype}
          subtype={subtype}
          signatureComponent={signatureComponent}
          sectionName={DependentFieldLabel[subtype]}
        />
      ))}
      <Templating id={id} component={component} />
    </div>
  )
}

const DependentSingleToolbar = ({ signatureComponent, subtype, sectionName }) => {
  const dispatch = useDispatch()
  const events = useEventManager()
  const { id, type, page, rect } = signatureComponent
  const createDependentFieldComponent = useDependentFieldCreator()
  const key = uniqueComponentKey(type, id, subtype)
  const component = useSelector((state) => state.components.all[key])

  const removeComponent = () => dispatch(removeComponents([key]))

  const addComponent = () => createDependentFieldComponent({id, type, page, rect: calNewRectDuplicateEl({ rect, page }), subtype})

  return (
    <div>
      {component ? (
        <Transform
          page={component?.page}
          onAction={removeComponent}
          position={component?.rect}
          id={splitComponentKey(key)}
          sectionName={sectionName}
          actionName={component ? 'remove' : 'add'}
        />
      ) : (
        <SectionDivider
          onAction={addComponent}
          sectionName={sectionName}
          actionName={component ? 'remove' : 'add'}
        ></SectionDivider>
      )}
    </div>
  )
}

export const DataSingleToolbar = ({ id, component }) => {
  return (
    <div className="side-toolbar data-toolbar scrollable">
      <Behaviors id={id} component={component} />
      <Transform id={id} page={component?.page} position={component?.rect} />
      <Templating id={id} component={component} />
    </div>
  )
}

export const DataMultipleToolbar = ({ components }) => {}
